*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/*

@import "./elements/grid.scss";

@import "./elements/topnav.scss";
@import "./elements/footer.scss";

@import "./page_grids.scss/homepage.scss";
*/
//@import "./page_style/homepage.scss";
/* Variables */
$vin-blue: #5bb7db;
$vin-green: #60b044;
$vin-red: #ff0000;

.homepage{
  //background-color: #c4f6ff;

 //background-color : rgb(1, 1, 80);
 background-color : rgb(31, 31, 58);

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 100%;
  width: 100%;
}
.mainContent{
  //border : black solid 2px;
  //background-color: white;
  //background-image: url('../../images/greenhousecenter.jpg');
  background-image: url('../../images/greenhouse1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
 
  //background-size: 100%;
  padding : 110px 0 100px 0;
  border : black solid 3px;

  
}

.mainContentImage{
  object-fit: fill;
  width : 100%;
  height: 100%;
  z-index: -1;
}

.homepage{
  display : block;
}

.companytitle{
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
 // border: solid 1px gray;
 font-size: 60px;
 @media (max-width : 710px) {
  font-size: 32px;
 }
  
  color: rgb(255, 255, 255);
  text-align: center;
  text-transform: normal;
  -o-text-overflow: clip;
  text-overflow: clip;
  white-space: pre;
  text-shadow: 3px 1px 0 rgba(0, 0, 0, 0.6) , -1px -1px 1px rgba(0, 0, 0, 0.67) ;
  padding : 10px;
  //margin-left: 20%;
  
}


@media (min-width: 1230px) {
  .ownerimage{
    background-image: url('../../images/ownerflower.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    z-index:1;
    position:fixed;
    left: 6%;
    top: 3%;
    height:200px;
    width:200px;
    background-repeat: no-repeat;
    background-position: left top;
    border: 3px black solid;
    box-shadow: -5px 10px #383838c4;
  }
}
.post-container {
  margin: 0% 30% 5% 30% ;  
  border: 1px solid #333;
  overflow: auto;
  background-color : rgba(181, 202, 202, 0.925);
  text-align: left;
  p{
    padding-top : 10px;
  }
}
.post-thumb-left {
  float: left
}
.post-thumb-left img {
  display: block;
  height : 300px;
  width : 500px;
  
}

.post-title-left{
  color: rgb(255, 255, 255);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.6) , -1px -1px 8px rgba(0, 0, 0, 0.67) ;
 // text-decoration: underline;
 border-bottom: solid black 1px;
background-color:rgba(108, 119, 119, 0.925);
}

.post-content-left {
  
  margin-bottom: 20px
}
.post-title-left {
  font-weight: bold;
  font-size: 200%
}

/*

.post-thumb-right {
  float: right
}
.post-thumb-right img {
  display: block;
  height : 300px;
  width : 500px;
  
}
.post-content-right {
  margin-right: 210px;
  
}
.post-title-right {
  //float : right;
  font-weight: bold;
  font-size: 200%
}

*/


.foot{
  text-align : center;
  padding-top: 30px;
  height : 120px
  
}

.footer {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  color : white;
}